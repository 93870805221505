.space{
  margin: 10px;
}

.head-search{
  padding:10px
}

.main-view{
  margin: 10px;
}